const commonMethods = {
    // AUTHORITYCODE: 权限编码  【0:不可编辑不可删除, 1:可删除, 2:可编辑, 3:可编辑可删除】

    // 查看
    showReadBtn(row) {
        if (Number(row.AUTHORITYCODE) === 0) {
            return true;
        } else {
            return false;
        }
    },
    // 修改
    showEditBtn(row) {
        if (Number(row.AUTHORITYCODE) === 2 || Number(row.AUTHORITYCODE) === 3) {
            return true;
        } else {
            return false;
        }
    },
    // 删除
    showDelBtn(row) {
        if (Number(row.AUTHORITYCODE) === 1 || Number(row.AUTHORITYCODE) === 3) {
            return true;
        } else {
            return false;
        }
    },
};
export { commonMethods };