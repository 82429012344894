<template>
    <div class="assess-target-grid">
        <AssessHeader
            :currentStep="1"
            :dataSource="assessHeaderData"
        ></AssessHeader>
        <sn-table-group
            class="table"
            :tableGroupAttributes="tableGroupAttributes"
            @handleChange="handleChange"
            :commonMethods="commonMethods"
        ></sn-table-group>
    </div>
</template>
<script>
import { commonData } from "../sn-table-group-common";
import { selfData } from "./model/1_sn-table-group";
import commonMethodMixin from "../commonMethodMixin";
import { mapActions } from "vuex";
import { commonMethods } from "./model/1_table-methods";
import AssessHeader from "./components/assess_header";
export default {
    name: "assess_target_grid",
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            commonMethods,
            selfData,
            infoPage: "assess_target_form",
            // 筛选项
            keyword: "", // 关键字
            dxlxValue: "", //对象类型选择的值
            assessHeaderData: {
                title: "评估对象",
                content:
                    "评估对象指景迈山古茶林文化景观遗产本体以及对其安全性造成影响的因素。例如整个遗产、某个遗产要素、某个病害。",
            },
            exportCode: "2001",
            exportName: "评估对象",
        };
    },
    components: {
        AssessHeader,
    },
    mounted() {
        this.getDxlxList();
    },
    methods: {
        ...mapActions([
            "GetEnumItem", //获取枚举列表
            "GetPgdxInfoList", //获取评估对象信息列表
            "DeletePgdxInfo", //删除评估对象信息
        ]),
        async getTableData() {
            // 处理筛选
            this.dxlxValue = "";
            if (this.searchData && this.searchData.length > 0) {
                // eslint-disable-next-line no-unused-vars
                this.searchData.forEach((item, index) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        if (item.type === "PGDXLX") {
                            this.dxlxValue = item.value;
                        }
                    }
                });
            }

            this.tableGroupAttributes.loading = true;
            let res = await this.GetPgdxInfoList({
                Key: this.keyword, //String 内存放评估对象名称
                Jcdxlx: this.dxlxValue, //String 内存放评估对象类型
                pageIndex: this.tableGroupAttributes.snPage.currentPage, //Int 页数
                pageSize: this.tableGroupAttributes.snPage.tiaoshu, //Int 页面数据量
            });
            this.tableGroupAttributes.snTable.table.data = res.RESULT || [];
            this.tableGroupAttributes.snPage.count = Number(res.COUNT) || 0;
            this.tableGroupAttributes.loading = false;
        },
        // 获取对象类型列表
        async getDxlxList() {
            this.tableGroupAttributes.snSearch.selectData[0].list = [];
            let result = await this.GetEnumItem({
                Enumid: "b0d6c15a-7949-11ea-bd8d-000c2977b7fd",
            });
            if (result && result.length > 0) {
                this.tableGroupAttributes.snSearch.selectData[0].list = result;
            }
        },
        // 删除数据
        // eslint-disable-next-line no-unused-vars
        async delData(id, done, instance) {
            let res = await this.DeletePgdxInfo({
                Id: id,
            });
            this.delOver(res, done);
        },
        delOver(res, done) {
            if (res.ISSUCCESS) {
                done();
                this.$message({
                    message: res.RESULTDESCRIPTION,
                    type: "success",
                });
                // this.tableGroupAttributes.snPage.currentPage = 1;
                this.getTableData();
            } else {
                this.$message({
                    message: res.RESULTDESCRIPTION,
                    type: "error",
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.assess-target-grid {
    width: 100%;
    height: 100%;
    .table {
        width: 100%;
        height: calc(100% - 150px);
    }
}
</style>